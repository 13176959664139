export const timedTabs = () => {
    document.querySelectorAll("[vdx-tabs-timed]").forEach(tabContainer => {
        const tabs = tabContainer.querySelectorAll("a");
        const tabCount = tabs.length;
        let currentIndex = 0;
        const interval = parseInt(tabContainer.getAttribute("vdx-tabs-timed")) || 3000;

        const switchTab = () => {
            tabs[currentIndex].click();
            currentIndex = (currentIndex + 1) % tabCount;
        };

        setInterval(switchTab, interval);
    });
};
