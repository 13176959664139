export const formatDate = () => {
    try {
        const now = new Date();
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayName = dayNames[now.getDay()];
        const year = now.getFullYear();
        const date = now.getDate();

        const suffix = (day) => {
            const j = day % 10,
                k = day % 100;
            if (j == 1 && k != 11) return day + "st";
            if (j == 2 && k != 12) return day + "nd";
            if (j == 3 && k != 13) return day + "rd";
            return day + "th";
        };

        const formatMap = {
            "vdx-date-day": (format, lang) => {
                let day = dayName;
                if (lang) try {
                    day = now.toLocaleString(lang, { weekday: "long" });
                } catch {
                    console.error(`Invalid language code "${lang}". Defaulting to the full day name.`);
                }
                switch (format) {
                    case "short":
                        return day.slice(0, 3);
                    case "narrow":
                        return day.slice(0, 2);
                    default:
                        return day;
                }
            },
            "vdx-date-year": (format) => format === "2-digit" ? String(year).slice(2) : year,
            "vdx-date-month": (format, lang) => {
                try {
                    return now.toLocaleString(lang || "default", { month: format || "long" });
                } catch {
                    console.error(`Error processing month format "${format}" or language "${lang}". Defaulting to "long" format and default language.`);
                    return now.toLocaleString("default", { month: "long" });
                }
            },
            "vdx-date-date": (format) => format === "suffix" ? suffix(date) : format === "2-digit" ? String(date).padStart(2, "0") : date,
        };

        document.querySelectorAll("[vdx-date-day], [vdx-date-year], [vdx-date-month], [vdx-date-date]").forEach(element => {
            let attrType, format, lang;
            if (element.hasAttribute("vdx-date-day")) {
                attrType = "vdx-date-day";
                format = element.getAttribute("vdx-date-day");
            } else if (element.hasAttribute("vdx-date-year")) {
                attrType = "vdx-date-year";
                format = element.getAttribute("vdx-date-year");
            } else if (element.hasAttribute("vdx-date-month")) {
                attrType = "vdx-date-month";
                format = element.getAttribute("vdx-date-month");
            } else if (element.hasAttribute("vdx-date-date")) {
                attrType = "vdx-date-date";
                format = element.getAttribute("vdx-date-date");
            }
            lang = element.getAttribute("vdx-date-lang");
            const formatter = formatMap[attrType];
            if (formatter) {
                try {
                    const content = formatter(format, lang);
                    requestAnimationFrame(() => {
                        element.textContent = content;
                        console.log(`Updated element with ${attrType}="${format}", and vdx-date-lang="${lang}" to content: ${content}`);
                    });
                } catch (err) {
                    console.error(`Error generating content for ${attrType}.`, err);
                }
            } else {
                console.error(`Unknown attribute type: "${attrType}".`);
            }
        });
    } catch (error) {
        console.error("An unexpected error occurred while processing date elements.", error);
    }
};
