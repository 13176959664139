export const transitionDelay = () => {
    const getTransitionDelay = (element) => {
        while (element) {
            const delay = element.getAttribute("vdx-transition-delay");
            if (delay !== null) return delay;
            element = element.parentElement;
        }
        return null;
    };

    document.querySelectorAll("a").forEach(link => {
        link.addEventListener("click", (event) => {
            event.preventDefault();
            const url = link.href;
            const delay = getTransitionDelay(link);

            if (delay === "false") {
                setTimeout(() => {
                    window.location.href = url;
                }, 0);
            } else if (delay !== null) {
                const delayMs = parseInt(delay) || 0;
                setTimeout(() => {
                    window.location.href = url;
                }, delayMs);
            } else {
                window.location.href = url;
            }
        });
    });
};
